import "./style.css";
import * as THREE from "three";
import {
  MapControls,
  OrbitControls,
} from "three/examples/jsm/controls/OrbitControls";

//Scene
const scene = new THREE.Scene();

// TextureLoader
const textureLoader = new THREE.TextureLoader()
const colorTexture = textureLoader.load('/texture/color.jpg');
const matcapTexture  = textureLoader.load('/texture/mat2.png');
const bumpTexture  = textureLoader.load('/texture/bump.jpg');

//Resizing
window.addEventListener("resize", () => {
  //Update Size
  aspect.width = window.innerWidth;
  aspect.height = window.innerHeight;

  //New Aspect Ratio
  camera.aspect = aspect.width / aspect.height;
  camera.updateProjectionMatrix();

  //New RendererSize
  renderer.setSize(aspect.width, aspect.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

// Lights
const abmientLight = new THREE.AmbientLight(0xffffff, 0.5);
const pointLight = new THREE.PointLight(0xffffff, 0.5);
pointLight.position.set(2, 2, 2);
scene.add(abmientLight, pointLight);

//Mesh
// const geometry = new THREE.PlaneBufferGeometry(1,1);
const geometry =  new THREE.SphereGeometry(.3,100);
// const geometry = new THREE.PlaneBufferGeometry(1, 1, 64, 64);
// const geometry = new THREE.PlaneBufferGeometry(1, 1, 64, 64);
// const material = new THREE.MeshBasicMaterial({ map: colorTexture });
// const geometry = new THREE.TorusGeometry(0.3, 0.2, 32, 32);
// const material = new THREE.MeshBasicMaterial();
// material.map = colorTexture;
// const material = new THREE.MeshDepthMaterial();
// const material = new THREE.MeshNormalMaterial();
// const material = new THREE.MeshMatcapMaterial();
// const material = new THREE.MeshLambertMaterial();
// const material = new THREE.MeshToonMaterial();
const material = new THREE.MeshStandardMaterial();
// material.map = bumpTexture;
// material.matcap = matcapTexture;

// material.shininess = 200;
// material.specular  = new THREE.Color("green");
// material.norma
// material.wireframe = true;
// material.color = new THREE.Color("skyblue");
// material.metalness = 0.5;
// material.roughness = .1;
material.map = colorTexture;
material.bumpMap = bumpTexture;
// material.transparent = true;
// material.opacity = 1;
// material.side = THREE.DoubleSide;
// material.visible = false;
const mesh = new THREE.Mesh(geometry, material);


// const geometry2 = new THREE.PlaneBufferGeometry(1, 1, 64, 64);
// const material2 = new THREE.MeshBasicMaterial();
// material2.map = colorTexture;

// const mesh2 = new THREE.Mesh(geometry2, material2);
// mesh2.position.z = -1

scene.add(mesh);



//Camera
const aspect = {
  width: window.innerWidth,
  height: window.innerHeight,
};
const camera = new THREE.PerspectiveCamera(75, aspect.width / aspect.height);
camera.position.z = 1;
scene.add(camera);

//Renderer
const canvas = document.querySelector(".draw");
const renderer = new THREE.WebGLRenderer({ canvas });
renderer.setSize(aspect.width, aspect.height);

//OrbitControls
const orbitControls = new OrbitControls(camera, canvas);
orbitControls.enableDamping = true;

//Clock Class
const clock = new THREE.Clock();
// mesh.rotation.z = 1;
const animate = () => {
  //GetElapsedTime
  const elapsedTime = clock.getElapsedTime();

  mesh.rotation.y = elapsedTime;
  // mesh.rotation.z = Math.sin(elapsedTime);


  //Update Controls
  orbitControls.update();

  //Renderer
  renderer.render(scene, camera);

  //RequestAnimationFrame
  window.requestAnimationFrame(animate);
};
animate();
